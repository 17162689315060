export enum Chart {
  PERF_ASSESS = "performance-assessment",
  SENTIMENT_BREAKDOWN = "sentiment-breakdown",
  SENTIMENT_TRACKER = "sentiment-tracker",
  EMOTIVE_ANALYSIS = "emotive-analysis",
  TRENDING_THEMES = "trending-themes",
}

export enum Classification {
  DEFAULT = "default",
  BALANCE_WELLBEING = "balance_&_wellbeing",
  BENEFITS_PERKS = "benefits_&_perks",
  CAREER_PROGRESSION = "career_progression",
  COMPANY_CULTURE = "company_culture",
  DIVERSITY_INCLUSION = "diversity_&_inclusion",
  INNOVATION_TECH = "innovation_&_technology",
  JOB_SATISFACTION = "job_satisfaction",
  LEARN_DEV = "learning_&_development",
  MANAGE_ORG = "management_&_organization",
  MISSION_PURPOSE = "mission_&_purpose",
  REMUNERATION = "remuneration",
  TEAM_PEOPLE = "team_&_people",
}
