import { EmotiveDataType } from "@/types/emotive";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  emotiveData: EmotiveDataType | any | null;
  loadingEmotiveData: boolean;
  initializedEmotiveData: boolean;
  errorEmotiveData: string | null;
}

export interface EmotiveSlice extends State {
  setEmotiveData: (EmotiveData: EmotiveDataType | any | null) => void;
  setLoadingEmotiveData: (loading: boolean) => void;
}

const INITIAL_STATE: State = {
  emotiveData: null,
  loadingEmotiveData: false,
  initializedEmotiveData: false,
  errorEmotiveData: null,
};

export const createEmotiveSlice: StateCreator<
  EmotiveSlice,
  [],
  [],
  EmotiveSlice
> = (set) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setEmotiveData: (data) => {
      set({ emotiveData: data, initializedEmotiveData: true });
    },
    setLoadingEmotiveData: (loading) => set({ loadingEmotiveData: loading }),
  };
};
