import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
interface State {
  rowSelection: any[];
}

const INITIAL_STATE: State = {
  rowSelection: [],
};

export interface DataTableSlice extends State {
  setRowSelection: (rowSelection: any[]) => void;
}

export const createDataTableSlice: StateCreator<
  DataTableSlice,
  [],
  [],
  DataTableSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setRowSelection: (selectedRows) => set({ rowSelection: selectedRows }),
    resetDataTable: () => set(INITIAL_STATE),
  };
};
