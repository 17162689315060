/**
 * This file contains functions to transform review data into a format suitable for charts.
 */
import { getColorCodeSentimentProgressBar } from "@/components/charts/employee-sentiment-tracker/getColorCodeSentimentProgressBar";
import { categoryAbbreviations, formatToCode } from "@ui/lib/utils";
import { Classification } from "@/types/execSummary";
import { format, startOfMonth, parseISO } from "date-fns";
import { slugify } from "@ui/lib/orgUtils";
import type { EmotiveItem, MonthlyData } from "@/types/emotive";
import type { Statistics } from "@/types/statistics";
import type { CompanyDataType } from "@/types/company";
import type { EmployeeSentimentTrackerData, ReviewType } from "@/types/review";

const posColors = ["#6ee7b7", "#34d399", "#10b981", "#059669"];
const negColors = ["#fda4af", "#fb7185", "#f43f5e", "#e11d48"];
const maxAbsValue = 10;

const attributes = [
  "Balance & Wellbeing",
  "Benefits & Perks",
  "Career Progression",
  "Company Culture",
  "Diversity & Inclusion",
  "Innovation & Technology",
  "Job Satisfaction",
  "Learning & Development",
  "Management & Organization",
  "Mission & Purpose",
  "Remuneration",
  "Team & People",
];

const chartKeyMap: { [key: string]: string } = {
  bw: Classification.BALANCE_WELLBEING,
  bp: Classification.BENEFITS_PERKS,
  cp: Classification.CAREER_PROGRESSION,
  cc: Classification.COMPANY_CULTURE,
  di: Classification.DIVERSITY_INCLUSION,
  it: Classification.INNOVATION_TECH,
  js: Classification.JOB_SATISFACTION,
  ld: Classification.LEARN_DEV,
  mo: Classification.MANAGE_ORG,
  mp: Classification.MISSION_PURPOSE,
  r: Classification.REMUNERATION,
  tp: Classification.TEAM_PEOPLE,
};

/**
 * Transforms reviews data into a structured format suitable for charting.
 * @param reviews Array of review data.
 * @returns ChartData A structured data for charts.
 */
export function transformIndexData(data: Statistics[]): ChartData {
  let chartData: ChartData = {
    mltc: {
      [Classification.BALANCE_WELLBEING]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.BENEFITS_PERKS]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.CAREER_PROGRESSION]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.COMPANY_CULTURE]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.DIVERSITY_INCLUSION]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.INNOVATION_TECH]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.JOB_SATISFACTION]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.LEARN_DEV]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.MANAGE_ORG]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.MISSION_PURPOSE]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.TEAM_PEOPLE]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
      [Classification.REMUNERATION]: {
        pros: 0,
        cons: 0,
        total: 0,
        squeeze_score: 0,
        proportion: 0,
      },
    },
    mltc_percent: {},
    quarterlyComparison: {},
    emotive: {},
  };
  // reviews is an object iterate over the reviews

  if (data.length > 0) {
    for (const [
      index,
      { category, totalCons, totalPros, proportionalScore },
    ] of Object.entries(data)) {
      if (!chartData.mltc[category]) {
        chartData.mltc[category] = {
          pros: 0,
          cons: 0,
          total: 0,
          proportion: 0,
          squeeze_score: 0,
        };
      }
      if (!chartData.mltc_percent[category]) {
        chartData.mltc_percent[category] = {
          pros: 0,
          cons: 0,
          total: 0,
          squeeze_score: 0,
          proportion: 0,
        };
      }
      if (data) {
        chartData.mltc[category].pros = totalPros;
        chartData.mltc[category].cons = totalCons;
        chartData.mltc[category].total = totalPros + totalCons;
        chartData.mltc[category].squeeze_score =
          calculateSqueezeScoreForCategory(
            chartData.mltc[category].pros,
            chartData.mltc[category].cons
          );
      }
      let pros = chartData.mltc[category].pros;
      let cons = chartData.mltc[category].cons;
      let total = chartData.mltc[category].total;

      chartData.mltc_percent[category].pros = (pros / total) * 100;
      chartData.mltc_percent[category].cons = (cons / total) * 100;
      chartData.mltc_percent[category].total = total;
      chartData.mltc_percent[category].squeeze_score =
        chartData.mltc[category].squeeze_score;
    }

    for (const [category, data] of Object.entries(chartData.mltc)) {
      if (data.total === 0) {
        chartData.mltc[category].proportion = 0;
      } else {
        chartData.mltc_percent[category].proportion = Number(
          ((data.pros / data.total) * 100).toFixed(0)
        );
      }
    }

    for (const [category, data] of Object.entries(chartData.mltc)) {
      if (data.total === 0) {
        chartData.mltc[category].proportion = 0;
      } else {
        chartData.mltc[category].proportion = Number(
          ((data.pros / data.total) * 100).toFixed(0)
        );
      }
    }
  }
  return chartData;
}

/**
 * Transforms reviews data into a format suitable for Treemap charts.
 * @param reviews Array of review data.
 * @param currentDate Current date for analysis.
 * @param months Number of months to consider.
 * @returns Array of transformed data for Treemap chart.
 */
export const transformForTreemapChart = (
  reviews: ReviewType[],
  currentDate: Date,
  months: number
) => {
  const prevPeriodEndDate = new Date(currentDate);
  prevPeriodEndDate.setMonth(currentDate.getMonth() - months);
  const prevPeriodStartDate = new Date(prevPeriodEndDate);
  prevPeriodStartDate.setMonth(prevPeriodEndDate.getMonth() - months);

  const currentPeriodReviews = filterReviewsForDateRange(
    reviews,
    prevPeriodEndDate,
    currentDate
  );
  const prevPeriodReviews = filterReviewsForDateRange(
    reviews,
    prevPeriodStartDate,
    prevPeriodEndDate
  );

  const currentCounts = aggregateCountsForPeriod(currentPeriodReviews);
  const prevCounts = aggregateCountsForPeriod(prevPeriodReviews);
  const transformedData = [];
  for (const category of Object.keys(currentCounts)) {
    const currentProsRatio =
      currentCounts[category].pros / currentCounts[category].total;
    const prevProsRatio = prevCounts[category]
      ? prevCounts[category].pros / prevCounts[category].total
      : 0;

    let delta = currentProsRatio - prevProsRatio;
    delta = delta * 100;
    delta = parseFloat(delta.toFixed(0));

    const displayDelta = Math.abs(delta);

    transformedData.push({
      name: category,
      value: displayDelta,
      originalValue: delta,
      currentCounts: currentCounts[category],
      prevCounts: prevCounts[category],
      itemStyle: { color: getValueColor(delta) }, // Multiplied by 10 to fit the range used in getValueColor
    });
  }

  return transformedData;
};

/**
 * Transform review data into a format suitable for Progress Bar
 * @param reviews Array of review data, grouped categories
 * @param currentDate Current date of analysis
 * @param months Number of months to consider
 * @return Array of transformed data for treemap chart
 */
export const transformForTreemapChartV1 = (
  sentimentBreakdownChartData: Statistics[],
  previousSentimentBreakdownChartData: Statistics[],
  competitorData: CompanyDataType[],
  selectedCompetitor: string
): EmployeeSentimentTrackerData[] => {
  const transformedData: EmployeeSentimentTrackerData[] = [];
  selectedCompetitor = slugify(selectedCompetitor || "");
  const selectedCompetitorData = competitorData.find(
    (val) => val && val.slug === selectedCompetitor
  );

  if (sentimentBreakdownChartData.length) {
    sentimentBreakdownChartData.forEach((currentSentiment) => {
      const previousSentiment = previousSentimentBreakdownChartData.find(
        (val) => val.category == currentSentiment.category
      );

      const tempSentiment: EmployeeSentimentTrackerData = {
        name: currentSentiment.category,
        originalValue: 0,
        competitorOriginalValue: 0,
        value: 0,
        competitorValue: 0,
        color: getColorCodeSentimentProgressBar(0, 0),
      };

      tempSentiment.value = currentSentiment.proportionalScore;
      if (previousSentiment) {
        tempSentiment.originalValue = previousSentiment.proportionalScore;
      }

      tempSentiment.color = getColorCodeSentimentProgressBar(
        tempSentiment.originalValue || 0,
        tempSentiment.value
      );

      if (selectedCompetitorData) {
        if (selectedCompetitorData.previousSentimentBreakdownChartData) {
          const competitorPreviousSentiment =
            selectedCompetitorData.previousSentimentBreakdownChartData.find(
              (val) => val.category == currentSentiment.category
            );

          if (competitorPreviousSentiment) {
            tempSentiment.competitorOriginalValue =
              competitorPreviousSentiment.proportionalScore;
          }
        }

        if (selectedCompetitorData.sentimentBreakdownChartData) {
          const competitorCurrentSentiment =
            selectedCompetitorData.sentimentBreakdownChartData.find(
              (val) => val.category == currentSentiment.category
            );

          if (competitorCurrentSentiment) {
            tempSentiment.competitorValue =
              competitorCurrentSentiment.proportionalScore;
          }
        }
      }

      transformedData.push(tempSentiment);
    });
  }

  if (!transformedData.length) {
    Object.values(categoryAbbreviations).forEach((category: string) => {
      transformedData.push({
        name: category,
        value: 0,
        originalValue: 0,
        competitorOriginalValue: 0,
        competitorValue: 0,
        color: getColorCodeSentimentProgressBar(0, 0),
      });
    });
  }

  return transformedData;
};

/**
 * Placeholder transformation function for BrandIndexChart.
 * @param reviews Array of review data.
 * @param comparitorReviews Array of review data for the competitor or index group to be compared to.
 * @returns TODO: Transformed data for BrandIndexChart.
 */
export const transformForBrandIndexChart = (
  reviews: ReviewType[],
  comparitorData: ReviewType[]
) => {
  return "transformedData";
};

/**
 * Transforms MLTCData into a format suitable for Bar charts.
 * @param currentCompanyData MLTC data for the current company.
 * @param competitorData MLTC data for the competitor.
 * @returns TransformedData Array of data suitable for Bar charts.
 */
export const transformBarChartData = (
  currentCompanyData: MLTCData,
  competitorData?: MLTCData
): TransformedData => {
  return attributes.map((attribute) => {
    const formattedAttribute = formatToCode(attribute);
    const competitor = competitorData
      ? {
          indexPros: [0, competitorData[formattedAttribute]?.pros || 0],
          indexCons: [0, -(competitorData[formattedAttribute]?.cons || 0)],
        }
      : {};
    return {
      attribute: attribute.replace(" & ", " \n& "),
      category: attribute.replace(" ", "_").toLowerCase(),
      companyPros: [0, currentCompanyData[formattedAttribute]?.pros || 0],
      companyCons: [0, -(currentCompanyData[formattedAttribute]?.cons || 0)],
      ...competitor,
    };
  });
};

/**
 * Transform reviews data into a format suitable for line chart
 * @param currentCompanyReviews Array of review data.
 * @param competitorReviews Array of review data for the competitor or index group to be compared to.
 * @returns Array of data suitable for line chart.
 */
export const transformLineChartData = (
  currentCompanyReviews: ReviewType[],
  competitorReviews?: ReviewType[]
) => {
  const currentCompanyData = aggregateCountsForPeriod(currentCompanyReviews);
  const competitorData = competitorReviews
    ? aggregateCountsForPeriod(competitorReviews)
    : null;

  return attributes.map((attribute) => {
    const formattedAttribute = formatToCode(attribute);

    const competitor = competitorData
      ? {
          indexPros: competitorData[formattedAttribute]?.pros || 0,
          indexCons: competitorData[formattedAttribute]?.cons || 0,
        }
      : {};
    return {
      attribute: attribute.replace(" & ", " \n& "),
      category: attribute.replace(" ", "_").toLowerCase(),
      companyPros: currentCompanyData[formattedAttribute]?.pros || 0,
      companyCons: currentCompanyData[formattedAttribute]?.cons || 0,
      ...competitor,
    };
  });
};

// Assuming a type for the input data based on the provided details
// Adjust this type according to the actual structure of your input data
type NewEmotiveDataType = {
  [month: string]: { [emotion: string]: { count: number; reviews: string[] } };
};

interface TransformedEmotiveData {
  emotiveFormattedData: EmotiveAggregatedData;
  totalEmotionsCount: EmotiveEmotionsCount;
  allEmotions: string[];
}

export function transformEmotiveData(
  emotiveData: EmotiveItem[] | []
): TransformedEmotiveData {
  const formattedEmotiveData = formatEmotiveData(emotiveData ?? []);
  const aggregatedData: EmotiveAggregatedData = {};
  const totalEmotionsCount: EmotiveEmotionsCount = {};
  let allEmotions = new Set<string>();

  for (const [month, emotions] of Object.entries(formattedEmotiveData)) {
    if (!aggregatedData[month]) {
      aggregatedData[month] = {};
    }

    for (const [emotion, { count }] of Object.entries(emotions)) {
      allEmotions.add(emotion);

      if (!aggregatedData[month][emotion]) {
        aggregatedData[month][emotion] = count;
      } else {
        aggregatedData[month][emotion] += count;
      }

      if (!totalEmotionsCount[emotion]) {
        totalEmotionsCount[emotion] = count;
      } else {
        totalEmotionsCount[emotion] += count;
      }
    }
  }

  allEmotions = new Set(Array.from(allEmotions));

  Object.keys(aggregatedData).forEach((monthKey) => {
    allEmotions.forEach((emotion) => {
      if (!aggregatedData[monthKey][emotion]) {
        aggregatedData[monthKey][emotion] = 0;
      }
    });
  });

  return {
    emotiveFormattedData: aggregatedData,
    totalEmotionsCount,
    allEmotions: Array.from(allEmotions),
  };
}

function formatEmotiveData(data: EmotiveItem[]): MonthlyData {
  const result: MonthlyData = {};
  data &&
    data.forEach((item) => {
      const reviewDate = parseISO(item.reviewDate);
      const startOfMonthDate = startOfMonth(reviewDate);
      const monthYear = format(startOfMonthDate, "MMMM yyyy");

      if (!result[monthYear]) {
        result[monthYear] = {};
      }

      const prosEmotion = item.emotion.pros;
      if (!result[monthYear][prosEmotion]) {
        result[monthYear][prosEmotion] = { count: 1 };
      } else {
        result[monthYear][prosEmotion].count++;
      }

      const consEmotion = item.emotion.cons;
      if (!result[monthYear][consEmotion]) {
        result[monthYear][consEmotion] = { count: 1 };
      } else {
        result[monthYear][consEmotion].count++;
      }
    });

  return result;
}

/**
 * Helper function to aggregate counts for a given period.
 * @param reviews Array of review data.
 * @returns Object containing aggregated counts for each category.
 */
function aggregateCountsForPeriod(reviews: ReviewType[]) {
  const counts: {
    [category: string]: { pros: number; cons: number; total: number };
  } = {};

  for (const review of reviews) {
    for (const [category, data] of Object.entries(review.kc)) {
      if (!counts[category]) {
        counts[category] = { pros: 0, cons: 0, total: 0 };
      }
      if (data) {
        counts[category].pros += data.p.length;
        counts[category].cons += data.c.length;
        counts[category].total += data.p.length + data.c.length;
      }
    }
  }

  return counts;
}

/**
 * Filter reviews for a given date range.
 * @param reviews
 * @param startDate
 * @param endDate
 * @returns
 */
function filterReviewsForDateRange(
  reviews: ReviewType[],
  startDate: Date,
  endDate: Date
): ReviewType[] {
  return reviews.filter((review) => {
    const reviewDate = new Date(review.d);
    return reviewDate >= startDate && reviewDate <= endDate;
  });
}

/**
 * Returns a color based on the value.
 * @param value
 * @returns
 */
function getValueColor(value: number) {
  const intensity = Math.abs(value) / maxAbsValue;

  let colorIdx = 0;
  if (intensity > 0.75) {
    colorIdx = 3;
  } else if (intensity > 0.5) {
    colorIdx = 2;
  } else if (intensity > 0.25) {
    colorIdx = 1;
  }

  return value >= 0 ? posColors[colorIdx] : negColors[colorIdx];
}

/**
 * Calculates the squeeze score for a category.
 * @param pro Number of pros for a category.
 * @param con Number of cons for a category.
 * @returns Squeeze score for a category.
 */
function calculateSqueezeScoreForCategory(pro: number, con: number): number {
  const α = 0.05;
  const T = pro + Math.abs(con);
  const P = pro / T;
  const W = Math.sqrt(T);
  const score = P + α * (P * (W - 1));
  return parseFloat(score.toFixed(2));
}

type MLTCData = {
  [category: string]: {
    pros: number;
    cons: number;
    total: number;
    squeeze_score: number;
  };
};

type TransformedData = {
  attribute: string;
  companyPros: number[];
  companyCons: number[];
  indexPros?: number[];
  indexCons?: number[];
}[];

export type ChartOutputData = {
  [category: string]: {
    pros: number;
    cons: number;
    total: number;
    squeeze_score: number | 0;
    proportion: number;
    leaderboard?: number;
  };
};

export type ChartData = {
  mltc: {
    [category: string]: {
      pros: number;
      cons: number;
      total: number;
      squeeze_score: number;
      proportion: number;
    };
  };
  mltc_percent: {
    [category: string]: {
      pros: number;
      cons: number;
      total: number;
      proportion: number;
      squeeze_score: number;
    };
  };
  quarterlyComparison: {
    [category: string]: { current: number; previous: number };
  };
  emotive: { [category: string]: { pros: number; cons: number } };
};

type EmotiveParsedData = {
  reviewDate: Date;
  prosEmotion: string | null;
  consEmotion: string | null;
};

type EmotiveAggregatedData = {
  [monthKey: string]: { [emotion: string]: number };
};

type EmotiveEmotionsCount = {
  [emotion: string]: number;
};

export const defaultCatData = {
  proportion: 0,
  leaderboard: 0,
  squeeze_score: 0,
  cons: 0,
  pros: 0,
  total: 0,
};
