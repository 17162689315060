import {
  FilterOptionsType,
  FilterStateType,
} from "@/components/chart/filter-bar";
import { FunctionalGroups, Locations } from "@/types/review";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  initializedLocationFunctionalData: Boolean;
  filterOptions: FilterOptionsType;
  filters: FilterStateType;
  locations: Locations[];
  functionalGroups: FunctionalGroups[];
}

export interface FiltersSlice extends State {
  filterOptions: FilterOptionsType;
  filters: FilterStateType;
  setFilters: (item: string, value: string | boolean | number) => void;
  setLocations: (data: Locations[]) => void;
  setFunctionalGroups: (data: FunctionalGroups[]) => void;
}

export const INITIAL_STATE: State = {
  initializedLocationFunctionalData: false,
  filterOptions: {
    hasDateFilter: false,
    hasCompetitorFilter: false,
    hasPercentageToggle: false,
    hasIndex: false,
    categoryFilter: "",
    hasLocationFilter: false,
    hasFunctionalGroupFilter: false,
    hasSourceFilter: false,
  },
  filters: {
    selectedMonths: "12",
    selectedCompetitor: "",
    selectedCategory: "",
    selectedEmotion: "",
    selectedSeriesIndex: 1,
    showPercentage: true,
    isExpanded: true,
    selectedLocation: "all",
    selectedFunctionalGroup: "all",
    selectedSource: "glass-door",
    isFilterSubscribed: false,
  },
  locations: [],
  functionalGroups: [],
};

export const createFiltersSlice: StateCreator<
  FiltersSlice,
  [],
  [],
  FiltersSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setFilters: (item, value) => {
      return set({ filters: { ...get().filters, [item]: value } });
    },
    setLocations: (data) => {
      set({ locations: data });
    },
    setFunctionalGroups: (data) => {
      set({ functionalGroups: data });
    },
  };
};
