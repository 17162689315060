import { ExecSummary } from ".prisma/client";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  execSummaryData: ExecSummary[] | null;
  currentExecSummary: ExecSummary | null;
  initializedExecSummaryData: boolean;
}

export interface ExecSummarySlice extends State {
  setExecSummaryData: (execSummaryData: ExecSummary[] | null) => void;
  getCurrentExecSummary: (
    chart?: string,
    classification?: string
  ) => ExecSummary | undefined;
}

export const INITIAL_STATE: State = {
  execSummaryData: null,
  currentExecSummary: null,
  initializedExecSummaryData: false,
};

export const createExecSummarySlice: StateCreator<
  ExecSummarySlice,
  [],
  [],
  ExecSummarySlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setExecSummaryData: (data) =>
      set({ execSummaryData: data, initializedExecSummaryData: true }),
    getCurrentExecSummary: (
      chart: string = "",
      classification: string = ""
    ) => {
      const execSummaries = get().execSummaryData;
      if (!execSummaries) return undefined;
      const current = execSummaries.find(
        (item) => item.chart === chart && item.classification === classification
      );
      return current;
    },
  };
};
