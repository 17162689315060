import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
import { ChartOutputData } from "@/lib/charts/reviewDataTransformers";

interface State {
  leaderboard: ChartOutputData | null;
  loadingLeaderBoardData: boolean;
  initializedLeaderBoard: boolean;
  showCategorySummary: boolean;
  errorLeaderBoard: string | null;
}

export interface LeaderBoardSlice extends State {
  setLeaderBoard: (leaderboard: ChartOutputData | null) => void;
  setLoadingLeaderBoardData: (loading: boolean) => void;
}

export const INITIAL_STATE: State = {
  leaderboard: null,
  loadingLeaderBoardData: false,
  initializedLeaderBoard: false,
  showCategorySummary: false,
  errorLeaderBoard: null,
};

export const createLeaderBoardSlice: StateCreator<
  LeaderBoardSlice,
  [],
  [],
  LeaderBoardSlice
> = (set) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setLeaderBoard: (data) =>
      set({ leaderboard: data, initializedLeaderBoard: true }),
    setLoadingLeaderBoardData: (loading) =>
      set({ loadingLeaderBoardData: loading }),
  };
};
