/**
 * Return the color based on the value for sentiment tracker chart.
 * @param value
 * @returns string
 */
export const getColorCodeSentimentProgressBar = (
  previousValue: number,
  newValue: number
): {
  dotColor: string;
  barColor: string;
  borderColor: string;
} => {
  let change = newValue - previousValue;

  let color = {
    dotColor: "",
    barColor: "",
    borderColor: "",
  };

  switch (true) {
    case change > 10:
      color.dotColor = "bg-green-600";
      color.barColor = "bg-green-400";
      color.borderColor = "border-green-400";
      break;
    case change > 5:
      color.dotColor = "bg-green-600";
      color.barColor = "bg-green-400";
      color.borderColor = "border-green-300";
      break;
    case change >= -5 && change <= 5:
      color.dotColor = "bg-amber-600";
      color.barColor = "bg-amber-400";
      color.borderColor = "border-amber-400";
      break;
    case change < -5:
      color.dotColor = "bg-red-600";
      color.barColor = "bg-red-400";
      color.borderColor = "border-red-300";
      break;
    case change < -10:
      color.dotColor = "bg-red-600";
      color.barColor = "bg-red-400";
      color.borderColor = "border-red-400";
      break;
    default:
      color.dotColor = "#16A34A";
      color.barColor = "#4ADE80";
      color.borderColor = "border-green-400";
      break;
  }

  return color;
};
