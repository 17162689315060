import { TrendingTermsType } from "@/types/trendingTerms";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  trendingTerms: TrendingTermsType[] | null;
  initializedTrendingTerms: boolean;
  errorTrendingTerms: string | null;
}

export interface TrendingTermsSlice extends State {
  setTrendingTerms: (trendingTerms: TrendingTermsType[] | null) => void;
}

export const INITIAL_STATE: State = {
  trendingTerms: null,
  initializedTrendingTerms: false,
  errorTrendingTerms: null,
};

export const createTermsSlice: StateCreator<
  TrendingTermsSlice,
  [],
  [],
  TrendingTermsSlice
> = (set) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setTrendingTerms: (data) =>
      set({ trendingTerms: data, initializedTrendingTerms: true }),
  };
};
