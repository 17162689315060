import { createUserSlice, UserSlice } from "./sliceUserStore";
import {
  TrendingTermsSlice,
  createTermsSlice,
} from "./sliceTrendingTermsStore";
import { createFiltersSlice, FiltersSlice } from "./sliceFiltersStore";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import {
  LeaderBoardSlice,
  createLeaderBoardSlice,
} from "./sliceLeaderBoardStore";
import { InsightsSlice, createInsightsSlice } from "./sliceSummarizationStore";
import { createReviewSlice, ReviewSlice } from "./sliceReviewsStore";
import {
  createCompetitorLeaderBoardSlice,
  CompetitorLeaderBoardSlice,
} from "./sliceCompetitorLeaderBoardStore";
import { createEmotiveSlice, EmotiveSlice } from "./sliceEmotiveStore";
import {
  CompetitorExecSummarySlice,
  createCompetitorExecSummarySlice,
} from "./sliceCompetitorExecSummary";
import {
  createExecSummarySlice,
  ExecSummarySlice,
} from "./sliceExecSummaryStore";
import { DataTableSlice, createDataTableSlice } from "./sliceDataTable";

export const useAppStore = createWithEqualityFn<
  TrendingTermsSlice &
    UserSlice &
    FiltersSlice &
    LeaderBoardSlice &
    InsightsSlice &
    ReviewSlice &
    CompetitorLeaderBoardSlice &
    EmotiveSlice &
    ExecSummarySlice &
    CompetitorExecSummarySlice &
    DataTableSlice
>()(
  subscribeWithSelector((...a) => {
    return {
      ...createReviewSlice(...a),
      ...createTermsSlice(...a),
      ...createUserSlice(...a),
      ...createFiltersSlice(...a),
      ...createLeaderBoardSlice(...a),
      ...createInsightsSlice(...a),
      ...createCompetitorLeaderBoardSlice(...a),
      ...createEmotiveSlice(...a),
      ...createExecSummarySlice(...a),
      ...createCompetitorExecSummarySlice(...a),
      ...createDataTableSlice(...a),
    };
  }),
  shallow
);
