import { CompanyDataType } from "@/types/company";
import { StateCreator } from "zustand";
import {
  ChartOutputData,
  transformIndexData,
} from "@/lib/charts/reviewDataTransformers";
import { Competitors } from "@/models/competitor";
import { sliceResetFns } from "./resetStores";
import { CategoriesDataType } from "@/lib/charts/mappedCategories";
import { SingleCategoryData } from "@/types/charts";

interface State {
  categoriesData: CategoriesDataType[] | null;
  companyAverage: SingleCategoryData | null;
  companyData: CompanyDataType | null;
  competitorData: CompanyDataType[];
  competitors: Competitors[];
  competitorSlugs: string[];
  transformedCompanyData: ChartOutputData;
  initializedCompanyData: boolean;
  initializedCompetitorsData: boolean;
  errorReviews: string | null;
}

export interface ReviewSlice extends State {
  setCompanyData: (data: CompanyDataType) => void;
  setCompetitorData: (data: CompanyDataType) => void;
  setCompetitorsData: (data: CompanyDataType[]) => void;
  setCompetitors: (data: Competitors[]) => void;
  getTransformedCompanyData: () => ChartOutputData;
  getTransformedCompetitorData: (competitorName?: string) => ChartOutputData;
}

export const INITIAL_STATE: State = {
  categoriesData: null,
  companyAverage: null,
  companyData: null,
  competitorData: [],
  competitors: [],
  competitorSlugs: [],
  transformedCompanyData: {},
  initializedCompanyData: false,
  initializedCompetitorsData: false,
  errorReviews: null,
};

export const createReviewSlice: StateCreator<
  ReviewSlice,
  [],
  [],
  ReviewSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    // Actions
    getTransformedCompanyData: () => {
      return transformIndexData(
        get().companyData?.sentimentBreakdownChartData || []
      ).mltc;
    },
    getTransformedCompetitorData: (competitorName = "") => {
      const competitorReviewData = get().competitorData!.find(
        (competitor) => competitor.name === competitorName
      );
      return transformIndexData(
        competitorReviewData?.sentimentBreakdownChartData || []
      ).mltc;
    },
    setCompanyData: (data) => {
      set({ companyData: data, initializedCompanyData: true });
    },
    setCompetitorsData: (data) => {
      set({ competitorData: data, initializedCompetitorsData: true });
      set({ transformedCompanyData: get().getTransformedCompanyData() });
    },
    setCompetitorData: (data) => {
      set((state) => {
        const _id = state.competitors.findIndex(
          (item) => item.name === data!.name
        );
        return {
          competitorData: [
            ...state.competitorData.slice(0, _id),
            data,
            ...state.competitorData.slice(_id + 1),
          ],
        };
      });
    },
    setCompetitors: (data) => {
      set({ competitors: data });
      set({ competitorSlugs: data.map((v) => v?.slug) });
    },
  };
};
